<template>
  <div id="assignment-rules-view" class="assignment-rules-container">
    <assignment-rule-sidebar :isSidebarActive="addRule" @closeAssignmentRuleSidebar="toggleRulesDataSidebar" :id="id" />
    <div :class="!HAS_ACCESS ? 'blur-background' : ''">
      <vx-card class="mb-4">
        <h4 class="text-primary">{{ $t('vue.assignmentRules') }}</h4>
        <h6 class="text-danger mt-3" style="font-weight: 400">{{ $t('vue.rulesInfo') }}</h6>
        <p class="mb-3 mt-6">{{ $t('vue.assignmentRulesInfo') }}</p>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button color="primary" type="filled" icon-size="24px" icon="add" @click="addNewRule">{{ $t('vue.addNewRule') }} </vs-button>
          </vs-row>
        </div>
      </vx-card>
      <vx-card v-if="assignment_rules && assignment_rules.length > 0">
        <vs-table ref="table" pagination :max-items="itemsPerPage" :data="assignment_rules" search :noDataText="$t('views.home.noDataAvailable')">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-end">
            <vs-dropdown vs-trigger-click class="cursor-pointer items-per-page-handle mr-4 mb-4">
              <div
                class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                style="border-radius: 5px"
              >
                <span class="mr-2">
                  {{ firstRecordNumber }} - {{ lastRecordNumber }} {{ $t('vue.of') }}
                  {{ queriedItems }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 3">
                  <span>3</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <template slot="thead">
            <vs-th sort-key="name" style="width: 40%">{{ $t('vue.nameOfRule') }}</vs-th>
            <vs-th sort-key="users" style="width: 40%">{{ $t('vue.enabled') }}</vs-th>
            <vs-th style="width: 300px">{{ $t('vue.action') }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <draggable tag="tbody" :value="data" @input="onDragInput" ghost-class="ghost" @start="onDragStart" @end="onDragEnd">
              <vs-tr :data="tr" v-for="(tr, index) in data" :key="tr.id">
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.name }}</p>
                </vs-td>

                <vs-td class="assignment-toggle-td">
                  <div>
                    <vs-button size="medium" type="border" :color="getChipColor(tr.enabled)" @click="toggleStatus(tr.id, !tr.enabled)">
                      <span style="width: 100%; height: 100%">{{ tr.enabled ? $t('vue.enabled') : $t('vue.disabled') }}</span>
                    </vs-button>
                  </div>
                </vs-td>
                <vs-td>
                  <div>
                    <vs-prompt
                      :title="$t('modals.deleteAssignmentRule')"
                      @cancel="onCloseModal"
                      @accept="deleteRule"
                      @close="onCloseModal"
                      color="danger"
                      :cancel-text="$t('vue.cancel')"
                      :accept-text="$t('vue.delete')"
                      :active.sync="removeRule"
                    >
                      <div class="con-exemple-prompt" v-if="selectedRule">
                        <p>
                          <strong>{{ selectedRule.name }}</strong>
                        </p>
                        {{ $t('modals.deleteAssignmentRuleQuestion') }}
                      </div>
                    </vs-prompt>
                    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                      <vx-tooltip :text="$t('vue.edit')" position="top" class="ml-3">
                        <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-edit" @click="() => editRule(tr.id)"></vs-button>
                      </vx-tooltip>
                      <vx-tooltip :text="$t('vue.delete')" position="top" class="ml-3">
                        <vs-button color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="() => onDeleteRule(tr)"></vs-button>
                      </vx-tooltip>

                      <vx-tooltip :text="$t('vue.moveUp')" position="top" class="ml-3">
                        <vs-button
                          color="dark"
                          type="border"
                          size="medium"
                          icon-pack="feather"
                          icon="icon-arrow-up"
                          :disabled="index === 0"
                          @click="() => moveUp(tr, index)"
                        ></vs-button>
                      </vx-tooltip>
                      <vx-tooltip :text="$t('vue.moveDown')" position="top" class="ml-3">
                        <vs-button
                          color="dark"
                          type="border"
                          size="medium"
                          icon-pack="feather"
                          icon="icon-arrow-down"
                          :disabled="queriedItems === 0 || index + 1 === queriedItems"
                          @click="() => moveDown(tr, index)"
                        ></vs-button>
                      </vx-tooltip>
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </draggable>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <routing-message v-if="!HAS_ACCESS"></routing-message>
  </div>
</template>
<style scoped></style>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)
import { mapGetters, mapMutations } from 'vuex'
import AssignmentRuleSidebar from './AssignmentRuleSidebar.vue'
import _ from 'underscore'

import draggable from 'vuedraggable'

import RoutingMessage from '@/components/upgrade-messages/RoutingMessage.vue'

export default {
  components: {
    AssignmentRuleSidebar,
    draggable,
    RoutingMessage
  },
  data() {
    return {
      itemsPerPage: 50,
      addRule: false,
      selectedRule: null,
      id: null,
      removeRule: false,
      dragging: false
    }
  },
  computed: {
    ...mapGetters({
      rules: 'assignment_rules',
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    firstRecordNumber() {
      let currentPage = 1
      if (this.$refs.table && this.$refs.table.currentx) {
        currentPage = this.$refs.table.currentx
      }
      if (this.queriedItems === 0) {
        return 0
      }

      const x = (currentPage - 1) * this.itemsPerPage
      return x + 1
    },
    lastRecordNumber() {
      const recordNumber = this.firstRecordNumber + this.itemsPerPage - 1
      return recordNumber < this.queriedItems ? recordNumber : this.queriedItems
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.assignment_rules.length
    },
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('routing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    assignment_rules() {
      return _.sortBy(this.rules, 'position')
    }
  },
  async mounted() {
    const vm = this
    vm.$vs.loading()

    vm.$serverBus.$on('edit-rule', (id) => {
      vm.id = null
      setTimeout(() => {
        vm.id = id
        vm.addRule = true
      }, 100)
    })

    vm.$serverBus.$on('move-rule-up', async (obj) => {
      vm.$vs.loading()
      const nodeMoveUp = vm.assignment_rules.find((x) => x.id === obj.id)
      const nodeMoveDown = vm.assignment_rules[obj.newIndex]
      const upIndex = obj.newIndex
      const downIndex = obj.newIndex + 1

      if (nodeMoveUp && nodeMoveUp.id && nodeMoveDown && nodeMoveDown.id) {
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(nodeMoveUp.id).set({ position: upIndex }, { merge: true })
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(nodeMoveDown.id).set({ position: downIndex }, { merge: true })
      }
      vm.$vs.loading.close()
    })

    vm.$serverBus.$on('move-rule-down', async (obj) => {
      vm.$vs.loading()
      const nodeMoveDown = vm.assignment_rules.find((x) => x.id === obj.id)
      const nodeMoveUp = vm.assignment_rules[obj.newIndex]
      const downIndex = obj.newIndex
      const upIndex = obj.newIndex - 1

      if (nodeMoveUp && nodeMoveUp.id && nodeMoveDown && nodeMoveDown.id) {
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(nodeMoveUp.id).set({ position: upIndex }, { merge: true })
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(nodeMoveDown.id).set({ position: downIndex }, { merge: true })
      }
      vm.$vs.loading.close()
    })
    setTimeout(() => {
      vm.$vs.loading.close()
    }, 500)
  },
  beforeDestroy() {
    this.$serverBus.$off('edit-rule')
    this.$serverBus.$off('move-rule-up')
    this.$serverBus.$off('move-rule-down')
  },
  methods: {
    ...mapMutations({
      updateAssignmentRules: 'UPDATE_ASSIGNMENT_RULES'
    }),
    onDragStart() {
      this.dragging = true
    },
    onDragEnd() {
      this.dragging = false
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    onDragInput(list) {
      this.$vs.loading()
      const rulesWithUpdatedPosition = list.map((el, index) => {
        const newEl = {
          ...el,
          position: index
        }
        return newEl
      })
      rulesWithUpdatedPosition.forEach(async (el) => {
        await this.$db.collection('company').doc(this.activeUserInfo.company).collection('rules').doc(el.id).set({ position: el.position }, { merge: true })
      })
      this.updateAssignmentRules(rulesWithUpdatedPosition)
    },
    onCloseModal() {
      this.removeRule = false
      this.selectedRule = null
      this.id = null
    },
    onDeleteRule(tr) {
      this.removeRule = true
      this.selectedRule = tr
      this.id = tr.id
    },
    editRule(id) {
      this.id = null
      setTimeout(() => {
        this.id = id
        this.addRule = true
      }, 100)
    },
    moveUp(tr, index) {
      this.$serverBus.$emit('move-rule-up', {
        id: tr.id,
        newIndex: index - 1
      })
    },
    moveDown(tr, index) {
      this.$serverBus.$emit('move-rule-down', {
        id: tr.id,
        newIndex: index + 1
      })
    },
    async deleteRule() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        const vm = this
        setTimeout(async () => {
          vm.removeRule = false
          await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(vm.id).delete()
          vm.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('vue.changesSavedSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }, 500)
      }
    },
    getChipColor(value) {
      if (value) {
        return 'success'
      }
      return 'danger'
    },
    addNewRule() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.id = null
        this.addRule = true
      }
    },
    toggleRulesDataSidebar(val = false) {
      this.addRule = val
    },
    async toggleStatus(id, status) {
      const vm = this
      if (id && vm.activeUserInfo.company) {
        await vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules').doc(id).set({ enabled: status }, { merge: true })
      }
    }
  }
}
</script>

<style lang="scss">
#assignment-rules-view {
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values {
    .vs-table--td.assignment-toggle-td {
      > span {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.blur-background {
  filter: blur(2px);
}
.assignment-rules-container {
  min-height: 300px;
}
</style>
